<template>
    <div class="d-flex">
        <div v-for="dir in ['left', 'grow', 'right']" :class="[dir === 'grow' ? 'flex-grow-1' : '']">
            <FormActionButton
                :class="[dir === 'left' ? 'mr-1' : 'ml-1']"
                v-for="(btn, index) in buttons[position + '-' + dir]"
                :item="btn"
                :key="index"
            />
        </div>
    </div>
</template>

<script>
import FormActionButton from "@Platon/components/form/FormActionButton.vue"

export default {
    name: "FormActionButtonBar",
    components: { FormActionButton },
    props: {
        buttons: {},
        position: {},
        form: {}
    },

    provide() {
        return {
            form: this.form
        }
    }
}
</script>
